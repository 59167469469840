
import { BASE } from './index';
import axios from './http';

const print = {
  //app审批流数据
  entrypcinfo: params => axios.post(`${BASE.PRO}/api/oa/pc/info`, params ),
  //app审批列表
  usertodo: params => axios.post(`${BASE.PRO}/api/approval/user/todo`, params ),
  //app审批列表
  qiyuesuolist: params => axios.post(`${BASE.PRO}/api/qiyuesuo/list`, params ),
  //流程处理
  approvaldeal: params => axios.post(`${BASE.PRO}/api/approval/deal`, params ),
  //流程作废
  approvaldead: params => axios.post(`${BASE.PRO}/api/approval/dead`, params ),
  //选择加签的人员
  approvalusers: params => axios.post(`${BASE.PRO}/api/approval/postille/users`, params ),
  //添加加签人员
  approvaladd: params => axios.post(`${BASE.PRO}/api/approval/postille/add`, params ),
  //删除加签人员
  approvaldel: params => axios.post(`${BASE.PRO}/api/approval/postille/del`, params ),
  //获取下拉框状态值
  flowselect: params => axios.get(`${BASE.PRO}/api/oa/flow/select`, {params} ),
  //获取下拉框状态值
  qiyuesuoenum: params => axios.post(`${BASE.PRO}/api/qiyuesuo/enum`, params ),
};

export default print;
